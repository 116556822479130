
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    report: [Object, Array],
  },
})
export default class JobStatusTablePCOA extends Vue {
  declare $props: {
    report: any;
  };

  public title: string[] = [];

  public description = [
    'PCOA Moved Up To 1 Mile',
    'PCOA Moved 1 to 5 Miles',
    'PCOA Moved 5+ Miles',
    'NCOA Moved Up To 1 Mile',
    'NCOA Moved 1 to 5 Miles',
    'NCOA Moved 5+ Miles',
    'Cosmetic Change to Address',
    'Input Street Address Converted to a PO Box',
    'Input Rural Route Converted to a Street Address',
    'Confirmed Name at Address',
    'LACSLink Address',
    'Standardized Address (Name not Confirmed)',
    'DMA Mail Preference Suppression',
    'Moved, Left No Forwarding Address',
    'No Delivery Point Validation',
    'Deceased Suppression',
  ];

  created(): void {
    Object.keys(this.$props.report).forEach((i: string) => { if (i !== 'total') this.title.push(i); });
  }
}
