
import { Options, Vue } from 'vue-class-component';

import IconDragNDrop from '@/icons/other/DragNDrop.vue';

import { validateTypeFile } from '@/utils';

@Options({
  components: {
    IconDragNDrop,
  },
  emits: {
    file: Object,
  },
})
export default class ActiveJobDragNDrop extends Vue {
  declare $refs: {
    form: HTMLFormElement;
  }

  public uploadFile(e: Event): void {
    const file = (e.target as HTMLInputElement).files?.item(0);

    this.$emit('file', () => file);

    if (this.$refs.form) {
      this.$refs.form.reset();
    }
  }
}
