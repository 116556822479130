
import { Options, Vue } from 'vue-class-component';

import { JOB_TYPE } from '@/interface/job.interface';
import { downloadFileByLink } from '@/utils';

import requests from '@/requests';

@Options({})
export default class JobFileRequirements extends Vue {
  public downloadSampleFile(): void {
    requests.dashboard.downloadSampleFile(JOB_TYPE.ncoa).then((res) => { downloadFileByLink(res.data.file); });
  }
}
