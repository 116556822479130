
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    report: [Object, Array],
  },
})
export default class JobStatusTableEmailVerification extends Vue {
  declare $props: {
    report: any[];
  }

  public title = ['Mailable Status by Status'];

  created(): void {
    Object.keys(Object.values(this.$props.report)[0]).forEach((i: any) => this.title.push(i));
  }
}
