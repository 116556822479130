<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 103 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M93.1693 0.0764044C92.6592 -0.135324 92.0741 0.106544 91.8623 0.616632L88.412 8.92899C88.2003 9.43908 88.4422 10.0242 88.9522 10.236C89.4623 10.4477 90.0475 10.2058 90.2592 9.69573L93.3262 2.30696L100.715 5.37391C101.225 5.58564 101.81 5.34377 102.022 4.83368C102.234 4.32359 101.992 3.73845 101.482 3.52672L93.1693 0.0764044ZM7.73679 102.685C41.5753 111.917 69.5015 101.902 85.7522 81.7172C101.985 61.5552 106.437 31.4089 93.7101 0.618001L91.8618 1.382C104.349 31.5911 99.9084 60.9448 84.1943 80.463C68.4985 99.9585 41.4247 109.803 8.26321 100.756L7.73679 102.685Z"
      fill="white"
    />
    <circle cx="6" cy="101" r="6" fill="white" />
  </svg>
  <!-- eslint-enable -->
</template>
