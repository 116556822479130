<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M108.167 22C105.134 22 102.667 19.5323 102.667 16.5C102.667 13.4677 105.134 11 108.167 11C111.199 11 113.667 13.4677 113.667 16.5C113.667 19.5323 111.199 22 108.167 22ZM108.167 14.6667C107.155 14.6667 106.333 15.4917 106.333 16.5C106.333 17.5083 107.155 18.3333 108.167 18.3333C109.179 18.3333 110 17.5083 110 16.5C110 15.4917 109.179 14.6667 108.167 14.6667Z" fill="#7B98AE"/>
    <path d="M103.583 39.4167C105.607 39.4167 107.25 41.0593 107.25 43.0833V81.5833C107.25 83.6 105.6 85.25 103.583 85.25H28.4167C26.4 85.25 24.75 83.6 24.75 81.5833V43.0833C24.75 41.0593 26.3927 39.4167 28.4167 39.4167H103.583Z" fill="#F3F3F1"/>
    <path d="M24.75 85.25H107.25V92.5833C107.25 94.6 105.6 96.25 103.583 96.25H28.4167C26.4 96.25 24.75 94.6 24.75 92.5833V85.25Z" fill="#2FDF84"/>
    <path d="M73.3334 96.25C73.3334 96.25 73.3334 101.75 78.8334 107.25H53.1667C58.6668 101.75 58.5934 96.25 58.5934 96.25H73.3334Z" fill="#2FDF84"/>
    <path d="M33 81.5833V43.0833C33 41.0593 34.6427 39.4167 36.6667 39.4167H28.4167C26.3927 39.4167 24.75 41.0593 24.75 43.0833V81.5833C24.75 83.6 26.4 85.25 28.4167 85.25H36.6667C34.65 85.25 33 83.6 33 81.5833Z" fill="#D5DBE1"/>
    <path d="M33 92.5833V85.25H24.75V92.5833C24.75 94.6 26.4 96.25 28.4167 96.25H36.6667C34.65 96.25 33 94.6 33 92.5833Z" fill="#00B871"/>
    <path d="M58.5934 96.25C58.5934 96.25 58.6667 101.75 53.1667 107.25H61.4167C66.9167 101.75 66.8434 96.25 66.8434 96.25H58.5934Z" fill="#00B871"/>
    <path d="M63.25 25.663H68.75V55H63.25V25.663Z" fill="#383B53"/>
    <path d="M74.25 36.8903L66 28.6403L57.75 36.8903L53.8596 33L64.053 22.8067C65.1273 21.7323 66.869 21.7323 67.9433 22.8067L78.1403 33L74.25 36.8903Z" fill="#383B53"/>
    <path d="M77.9168 66H54.0834C50.5451 66 47.6667 63.1217 47.6667 59.5833V51.3333H53.1667V59.5833C53.1667 60.0893 53.5774 60.5 54.0834 60.5H77.9168C78.4228 60.5 78.8334 60.0893 78.8334 59.5833V51.3333H84.3334V59.5833C84.3334 63.1217 81.4551 66 77.9168 66Z" fill="#383B53"/>
    <path d="M103.583 99H28.4167C24.8783 99 22 96.1217 22 92.5834V43.0833C22 39.545 24.8783 36.6667 28.4167 36.6667H44.9167V42.1667H28.4167C27.9107 42.1667 27.5 42.5773 27.5 43.0833V92.5834C27.5 93.0894 27.9107 93.5 28.4167 93.5H103.583C104.089 93.5 104.5 93.0894 104.5 92.5834V43.0833C104.5 42.5773 104.089 42.1667 103.583 42.1667H87.0833V36.6667H103.583C107.122 36.6667 110 39.545 110 43.0833V92.5834C110 96.1217 107.122 99 103.583 99Z" fill="#383B53"/>
    <path d="M24.75 80.6667H107.25V86.1667H24.75V80.6667Z" fill="#383B53"/>
    <path d="M77.9166 110H54.0833C52.9723 110 51.9676 109.329 51.5423 108.302C51.117 107.276 51.3516 106.091 52.14 105.307C55.957 101.49 56.7783 95.9457 56.7856 95.8943L62.238 96.6093C62.2086 96.8257 61.699 100.518 59.51 104.5H72.4276C70.2606 100.54 69.7216 96.844 69.6923 96.6277L75.1373 95.865C75.1446 95.92 75.9476 101.211 79.4786 104.988C80.1973 105.486 80.6666 106.311 80.6666 107.25C80.6666 108.768 79.4346 110 77.9166 110Z" fill="#383B53"/>
  </svg>
  <!-- eslint-enable -->
</template>
