
import { Options, Vue } from 'vue-class-component';
import { WatchStopHandle } from 'vue';
import { LoaderStopPoint, LOADER_STATUS, LOADER_STATUS_TITLE } from '@/interface/other.interface';

@Options({
  props: {
    stops: Array,
  },
  emits: {
    status: String,
  },
})
export default class LoaderFile extends Vue {
  declare $props: {
    stops: LoaderStopPoint[];
  }

  public LOADER_STATUS = LOADER_STATUS;

  public LOADER_STATUS_TITLE = LOADER_STATUS_TITLE;

  public percent = 0;

  public opacityLoaderStripe = 1; // 0 - 1;

  public intervalTime = 0;

  // FLOW OF FILE UPLOAD =  uploading -> pleaseWait -> finished;
  public status: LOADER_STATUS = LOADER_STATUS.uploading;

  private interval = -1;

  private watchStopHandle!: WatchStopHandle;

  private statusError(): void {
    this.status = LOADER_STATUS.error;
    this.opacityLoaderStripe = 1;

    this.triggerEmit();
  }

  private uploading(): void {
    this.interval = setInterval(() => {
      if (this.$store.state.currentStop === LOADER_STATUS.finished) {
        clearInterval(this.interval);

        this.fastTo100();
      } else if (this.percent === this.$props?.stops[this.$store.state.currentStop]?.percent) {
        clearInterval(this.interval);
      } else if (this.percent === 100) {
        clearInterval(this.interval);

        this.pleaseWait();
      } else {
        this.percent += 1;
      }
    }, this.intervalTime);
  }

  private pleaseWait(): void {
    this.status = LOADER_STATUS.pleaseWait;

    this.triggerEmit();

    this.interval = setInterval(() => {
      if (this.percent === 2) {
        this.percent = 0;
        this.opacityLoaderStripe = 0;

        clearInterval(this.interval);

        this.finished();
      } else {
        this.percent -= 2;

        if (this.percent <= 10) {
          this.opacityLoaderStripe -= 0.1;
        }
      }
    }, 50);
  }

  private finished(): void {
    this.status = LOADER_STATUS.finished;

    this.triggerEmit();
  }

  private fastTo100(): void {
    const num = this.getRandomInt(70, 90);

    if (this.percent < 70) {
      this.percent = 70;
    }

    this.interval = setInterval(() => {
      if (this.percent !== 100) {
        this.percent += 1;
      } else {
        clearInterval(this.interval);

        this.pleaseWait();
      }
    }, 50);
  }

  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private triggerEmit(): void {
    this.$emit('status', this.status);
  }

  created(): void {
    if (this.$props?.stops && this.$props.stops[this.$store.state.currentStop]) {
      this.intervalTime = this.$props.stops[this.$store.state.currentStop].timeManipulation;
    }

    this.uploading();

    this.watchStopHandle = this.$store.watch(() => this.$store.state.currentStop, (stop: LOADER_STATUS | number) => {
      if (stop === LOADER_STATUS.error) {
        clearInterval(this.interval);

        this.statusError();
      } else {
        clearInterval(this.interval);

        this.uploading();
      }
    });
  }

  unmounted(): void {
    this.watchStopHandle();

    this.$store.commit('updateCurrentStopLoader', -1);
  }
}
