
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import { watch, WatchStopHandle, ref } from 'vue';

import IconWarning from '@/icons/service/Warning.vue';
import Tutorial from '@/common/Tutorial.vue';

import requests from '@/requests';
import { JobMapFields, JobMapFieldsData, JobMapFieldsFile } from '@/interface/job.interface';

@Options({
  components: {
    IconWarning,
    Tutorial,
  },
  computed: {
    ...mapState(['profile', 'tutorial']),
  },
  props: {
    mapping: Object,
  },
  emits: {
    allMapped: Object,
    result: Array,
  },
})
export default class WeFoundTable extends Vue {
  declare $props: {
    mapping: JobMapFields;
  }

  public perfectScrollbarOptions = {
    suppressScrollY: true,
  }

  public fileFieldsColumn: JobMapFieldsFile[] = [];

  public fileFieldsData: (string | null)[][] = [];

  public accessibleColumnTypes: string[] = [];

  public selectedColumnTypes: string[] = [];

  public requiredFields: string[] = [];

  public alternativesFields: string[][][] = [];

  public refDropdown = ref(null);

  public loaderTutorial = false;

  private defaultTitle = 'Not Mapped'

  private watchStopHandle!: WatchStopHandle;

  public selectNewColumnType(type: string, fieldsId: number): void {
    const array = [...this.selectedColumnTypes];

    const indexOldValue = array.findIndex((v) => v === type);

    if (indexOldValue !== -1) array.splice(indexOldValue, 1, this.defaultTitle);

    array.splice(fieldsId, 1, type);

    this.selectedColumnTypes = array;

    this.checkingRequiredFields();
    this.checkingAlternativesFields();
  }

  public submitUploadTutorial(): void {
    this.loaderTutorial = true;

    requests.profile.patchStatusTutorial({ mapping: true })
      .then((res) => {
        this.$store.commit('set', { key: 'tutorial', payload: res.data });
      })
      .finally(() => {
        this.loaderTutorial = false;
      });
  }

  /**
   * FINDING CHOSEN COLUMN TYPE NOT CONSIDERING this.defaultTitle;
   */
  public findChosenFieldByType(type: string): boolean {
    return type !== this.defaultTitle && this.selectedColumnTypes.some((v) => v === type);
  }

  private checkingRequiredFields(): void {
    let rawRequired: string[] = [];

    if (this.$props.mapping.required_fields?.length) rawRequired = [...this.$props.mapping.required_fields];

    const required: string[] = [];

    rawRequired.forEach((req) => {
      if (!this.selectedColumnTypes.some((v) => v === req)) { required.push(req); }
    });

    this.requiredFields = required;
  }

  private checkingAlternativesFields(): void {
    const sortAlternatives: string[][][] = [];

    let rawAlternatives: string[][][] = [];

    if (this.$props.mapping.alternatives?.length) rawAlternatives = [...this.$props.mapping.alternatives];

    rawAlternatives.forEach((rowAlternatives) => {
      let noOneIncludeInSelected = true;

      rowAlternatives.forEach((v) => {
        if (v.every((i) => this.selectedColumnTypes.includes(i))) noOneIncludeInSelected = false;
      });

      if (noOneIncludeInSelected) {
        sortAlternatives.push(rowAlternatives);
      }
    });

    this.alternativesFields = sortAlternatives;
  }

  /**
   *  SORTING COLUMN IF EMPTY < 100;
   */
  private mappingFields(): void {
    const rawFields: JobMapFieldsFile[] = JSON.parse(JSON.stringify(this.$props.mapping.file_fields));

    const fields: JobMapFieldsFile[] = [];

    rawFields.forEach((v) => { if (v.empty < 100) fields.push(v); });

    this.fileFieldsColumn = fields;

    this.mappingFieldsData();
  }

  /**
   * SORTING DATA BASED ON SORTED COLUMNS;
   */
  private mappingFieldsData(): void {
    const rowsFieldsData: JobMapFieldsData[] = JSON.parse(JSON.stringify(this.$props.mapping.data));

    rowsFieldsData.forEach((data) => {
      const array: (string | null)[] = [];

      Object.keys(data).forEach((v) => {
        if (this.fileFieldsColumn.some((c) => c.field === v)) array.push(data[v]);
      });

      this.fileFieldsData.push(array);
    });

    this.fillingAccessibleColumnTypes();
  }

  /**
   * FILLING ALL ACCESSIBLE TYPE FOR DROPDOWN;
   */
  private fillingAccessibleColumnTypes(): void {
    /* eslint-disable */
    if (this.$props.mapping.required_fields) this.accessibleColumnTypes = this.accessibleColumnTypes.concat(this.$props.mapping.required_fields);
    if (this.$props.mapping.secondary_fields) this.accessibleColumnTypes = this.accessibleColumnTypes.concat(this.$props.mapping.secondary_fields);
    /* eslint-enable */

    this.accessibleColumnTypes.sort();

    this.accessibleColumnTypes.unshift(this.defaultTitle);

    this.mappingSelectedColumnTypes();
  }

  /**
   * FIND AND PRE-SETUP SIMILAR TYPES FOR COLUMN FOR ACCESSIBLE TYPE;
   */
  private mappingSelectedColumnTypes(): void {
    const array: string[] = [];

    this.fileFieldsColumn.forEach((fileFields) => {
      if (this.accessibleColumnTypes.some((type) => type === fileFields.field)) {
        array.push(fileFields.field);
      } else {
        array.push(this.defaultTitle);
      }
    });

    this.selectedColumnTypes = array;
  }

  private watchChangedVariables(): void {
    if (!this.requiredFields.length && !this.alternativesFields.length) {
      this.$emit('allMapped', true);
      this.$emit('result', this.preparationResultsMapping());
    } else {
      // this is a crutch (null instead of false) because vue swears and does not call emit;
      this.$emit('allMapped', null);
      this.$emit('result', []);
    }
  }

  private preparationResultsMapping(): string[] {
    const rawArray = [...this.selectedColumnTypes];

    const array: string[] = [];

    rawArray.forEach((v, id) => {
      if (v !== this.defaultTitle) {
        array.push(v);
      } else {
        array.push(this.fileFieldsColumn[id].field);
      }
    });

    return array;
  }

  created(): void {
    this.mappingFields();
    this.checkingRequiredFields();
    this.checkingAlternativesFields();
    this.watchChangedVariables();

    this.watchStopHandle = watch(() => [this.requiredFields, this.alternativesFields], this.watchChangedVariables);
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
