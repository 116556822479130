import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!

  return (_openBlock(), _createBlock(_component_perfect_scrollbar, { class: "job-status-table" }, {
    default: _withCtx(() => [
      (_ctx.table)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.table), {
            key: 0,
            report: _ctx.$props.report
          }, null, 8, ["report"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}