/* eslint-disable camelcase, max-len */

export interface JobFileRequirements {
  required_fields: string[];
  alternatives?: Array<string[][]>;
  minimum_unique_lines?: number;
}

const ncoa: JobFileRequirements = {
  required_fields: ['Address 1'],
  alternatives: [
    [['City', 'State'], ['Zip'], ['Zip 5']],
  ],
  minimum_unique_lines: 100,
};

const consumer_pcoa: JobFileRequirements = {
  required_fields: ['Address 1', 'City', 'State'],
  alternatives: [
    [['First Name', 'Last Name'], ['Full Name']],
    [['City', 'State'], ['Zip']],
  ],
};

const cima: JobFileRequirements = {
  required_fields: [],
  alternatives: [
    [['Phone Number'], ['Phone Number 2'], ['Email'], ['First Name', 'Last Name'], ['Full Name'], ['Address 1', 'Zip 5'], ['Address 1', 'City', 'State'], ['Ip Address']],
  ],
};

const landline_phone_append: JobFileRequirements = {
  required_fields: ['Address 1', 'Zip 5'],
};

const wireless_phone_append: JobFileRequirements = {
  required_fields: ['Address 1', 'Zip 5'],
  alternatives: [
    [['First Name', 'Last Name'], ['Full Name']],
  ],
};

const premium_business_phone_and_contact_append: JobFileRequirements = {
  required_fields: [
    'DEBTOR',
    'ADDRESS',
    'CITY',
    'STATE',
    'ZIP',
  ],
};

const email_append_and_validate: JobFileRequirements = {
  required_fields: ['Address 1', 'Zip 5'],
  alternatives: [
    [['First Name', 'Last Name'], ['Full Name']],
  ],
};

const email_verification: JobFileRequirements = {
  required_fields: ['Email'],
};

const phone_disconnect: JobFileRequirements = {
  required_fields: ['Phone Number'],
};

const canadian_ncoa: JobFileRequirements = {
  required_fields: [
    'STREET',
    'CITY',
    'STATE',
    'ZIP',
  ],
  alternatives: [
    [['FIRST', 'LAST'], ['Full Name']],
  ],
};

const do_not_call_scrub: JobFileRequirements = {
  required_fields: ['Phone Number'],
};

const reverse_phone: JobFileRequirements = {
  required_fields: ['Phone Number'],
};

const reverse_email: JobFileRequirements = {
  required_fields: ['Email'],
};

const digital_audience: JobFileRequirements = {
  required_fields: [],
  alternatives: [
    [
      ['Address 1', 'City', 'State', 'First Name', 'Last name'],
      ['Address 1', 'Zip', 'First Name', 'Last name'],
      ['Phone', 'First Name', 'Last name'],
      ['Email', 'First Name', 'Last name'],
    ],
  ],
};

export const fileRequirements: { [key: string]: JobFileRequirements } = {
  ncoa,
  consumer_pcoa,
  digital_audience,
  cima,
  landline_phone_append,
  wireless_phone_append,
  premium_business_phone_and_contact_append,
  email_append_and_validate,
  email_verification,
  phone_disconnect,
  canadian_ncoa,
  do_not_call_scrub,
  reverse_phone,
  reverse_email,
};
