
import { Options, Vue } from 'vue-class-component';

import Modal from '@/common/base/Modal.vue';

import IconJobFileRequirements from '@/icons/other/JobFileRequirements.vue';
import { JOB_TYPE, JOB_TYPE_TITLE } from '@/interface/job.interface';
import JobFileRequirementsContainer from './components/JobFileRequirementsContainer.vue';

@Options({
  components: {
    Modal,
    IconJobFileRequirements,
    JobFileRequirementsContainer,
  },
  emits: {
    close: Boolean,
  },
})
export default class ModalJobFileRequirements extends Vue {
  public JOB_TYPE = JOB_TYPE;

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public selectType = JOB_TYPE.ncoa;

  public closeModal(): void {
    this.$emit('close', true);
  }
}
