
import { Options, Vue } from 'vue-class-component';
import { JOB_TYPE } from '@/interface/job.interface';

import FileRequirementsTooltip from '@/common/JobFileRequirements/FileRequirementsTooltip.vue';
import { JobFileRequirements, fileRequirements } from './JobFileRequirements/JobFileRequirements';

@Options({
  components: {
    FileRequirementsTooltip,
  },
  props: {
    type: String,
  },
})
export default class JobFileRequirementsContainer extends Vue {
  declare $props: {
    type: JOB_TYPE;
  }

  get requirements(): JobFileRequirements | null {
    return fileRequirements[this.$props.type];
  }
}
