
import { Options, Vue } from 'vue-class-component';
import { watch, WatchStopHandle } from 'vue';

import { JOB_TYPE } from '@/interface/job.interface';

import NoRequirements from './JobFileRequirements/NoRequirements.vue';

/* eslint-disable camelcase, max-len */
import ncoa from './JobFileRequirements/NCOA.vue';
// import canadian_ncoa from './JobFileRequirements/CanadianNCOA.vue';
// import cima from './JobFileRequirements/CIMA.vue';
// import consumer_pcoa from './JobFileRequirements/ConsumerPCOA.vue';
// import do_not_call_scrub from './JobFileRequirements/DoNotCallScrub.vue';
// import email_append_and_validate from './JobFileRequirements/EmailAppendAndValidate.vue';
// import email_verification from './JobFileRequirements/EmailVerification.vue';
// import landline_phone_append from './JobFileRequirements/LandlinePhoneAppend.vue';
// import phone_disconnect from './JobFileRequirements/PhoneDisconnect.vue';
// import premium_business_phone_and_contact_append from './JobFileRequirements/PremiumBusinessPhoneAndContactAppend.vue';
// import reverse_email from './JobFileRequirements/ReverseEmail.vue';
// import reverse_phone from './JobFileRequirements/ReversePhone.vue';
// import wireless_phone_append from './JobFileRequirements/WirelessPhoneAppend.vue';
/* eslint-enable camelcase */

@Options({
  components: {
    NoRequirements,
    ncoa,
    // canadian_ncoa,
    // cima,
    // consumer_pcoa,
    // do_not_call_scrub,
    // email_append_and_validate,
    // email_verification,
    // landline_phone_append,
    // phone_disconnect,
    // premium_business_phone_and_contact_append,
    // reverse_email,
    // reverse_phone,
    // wireless_phone_append,
  },
  props: {
    type: String,
  },
})
export default class JobFileRequirementsContainer extends Vue {
  declare $props: {
    type: JOB_TYPE;
  }

  public notNoRequirements = false;

  public watchStopHandle!: WatchStopHandle;

  private accessibleOptionsTypes: string[] = []

  private checkType(): void {
    this.notNoRequirements = this.accessibleOptionsTypes.some((v) => v === this.$props.type);
  }

  created(): void {
    const components = { ...this.$options.components };
    const types: JOB_TYPE[] = (Object.keys(components) as (JOB_TYPE | 'NoRequirements')[])
      .filter((v) => v !== 'NoRequirements') as JOB_TYPE[];

    this.accessibleOptionsTypes = types;

    this.checkType();

    this.watchStopHandle = watch(() => this.$props.type, () => this.checkType());
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
