
import { Options, Vue } from 'vue-class-component';
import { JobMapFields, ShortJobInfoInstance } from '@/interface/job.interface';

import Modal from '@/common/base/Modal.vue';

import requests from '@/requests';
import { ModalsState, ToggleModal } from '@/store/modules/modals';
import WeFoundTable from './components/WeFoundTable.vue';

@Options({
  components: {
    Modal,
    WeFoundTable,
  },
  props: {
    job: Object,
    mapping: Object,
  },
  emits: {
    close: Boolean,
    mapping: Boolean,
  },
})
export default class ModalWeFound extends Vue {
  declare $props: {
    job: ShortJobInfoInstance;
    mapping: JobMapFields;
  }

  public allMapped: true | null = null;

  public resultsMapped: string[] = [];

  // addition modal props
  public isRecomendModalOpen = false;

  public isNamesModalShowed = false;

  public closeModal(modal_name: string): void {
    if (modal_name === 'we_found') {
      this.$emit('close', true);
    }
    if (modal_name === 'names') {
      this.isRecomendModalOpen = false;
      this.isNamesModalShowed = true;
    }
  }

  public updateMapping(): void{
    // check if user map names fields
    const names_fields = ['First Name', 'Middle Name', 'Last Name', 'Full Name'];
    if (this.$props.job.job_type === 'ncoa' && !this.isNamesModalShowed && !(this.checkNamesMapping(names_fields))) {
      this.isRecomendModalOpen = true;
    } else if (this.allMapped && this.resultsMapped.length) {
      this.changeShowModalLoader(true);

      requests.dashboard.updateJobMap(this.$props.job.id, this.resultsMapped)
        .then(() => {
          this.$emit('mapping', true);
        })
        .finally(() => {
          this.changeShowModalLoader(false);
        });
    }
    // if (this.allMapped && this.resultsMapped.length) {
    //   this.changeShowModalLoader(true);

    //   requests.dashboard.updateJobMap(this.$props.job.id, this.resultsMapped)
    //     .then(() => {
    //       this.$emit('mapping', true);
    //     })
    //     .finally(() => {
    //       this.changeShowModalLoader(false);
    //     });
    // }
  }

  public openModal(modal: keyof ModalsState): void {
    this.$store.commit('modals/toggle', { modal, status: true } as ToggleModal);
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  private checkNamesMapping(fields: string[]) {
    let names_fields_exist = false;
    fields.forEach((field: string) => {
      if (this.resultsMapped.includes(field)) {
        names_fields_exist = true;
      }
    });
    return names_fields_exist;
  }
}
