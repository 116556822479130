
import { Options, Vue } from 'vue-class-component';
import { watch, WatchStopHandle } from 'vue';

import IconQuestionCircle from '@/icons/other/QuestionCircle.vue';

/* eslint-disable camelcase */
import zip_5 from './Tooltips/Zip5.vue';
/* eslint-enable camelcase */

@Options({
  components: {
    zip_5,
    IconQuestionCircle,
  },
  props: {
    field: String,
  },
})
export default class FileRequirementsTooltip extends Vue {
  declare $props: {
    field: string;
  }

  public showTooltip = false;

  public tooltip: string | null = null;

  public watchStopHandle!: WatchStopHandle;

  private accessibleTooltip: string[] = [];

  private getTooltip(): void {
    const field = this.$props.field.toLowerCase().split(' ').join('_'); // Address 1 -> address_1

    // eslint-disable-next-line
    this.accessibleTooltip.some((v) => v === field) ? this.tooltip = field : this.tooltip = null;
  }

  created(): void {
    const components = { ...this.$options.components };
    const types = Object.keys(components).filter((v) => v !== 'IconQuestionCircle');

    this.accessibleTooltip = types;

    this.getTooltip();

    this.watchStopHandle = watch(() => this.$props.field, () => this.getTooltip());
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
