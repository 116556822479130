
import { Options, Vue } from 'vue-class-component';
import { toParserDate } from '@/utils/date';

import ModalJobStatus from '@/modals/JobStatus.vue';

import IconsService from '@/icons/Service.vue';

import { FullJobInstance, JOB_TYPE_TITLE } from '@/interface/job.interface';

import IconSmallArrow from '@/icons/service/SmallArrow.vue';

import requests from '@/requests';
import { JobListResponse } from '@/interface/requests.interface';
import { SearchDashboardJobs } from '@/interface/other.interface';
import JobFileName from '../../../common/JobFileName.vue';

@Options({
  components: {
    ModalJobStatus,
    IconsService,
    IconSmallArrow,
    JobFileName,
  },
  props: {
    table: Object,
    loader: Boolean,
  },
  emits: {
    search: [String, Object],
    filter: [String, Object],
  },
})
export default class CompletedJob extends Vue {
  declare $props: {
    table: JobListResponse,
    loader: boolean;
  }

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public jobStatusData: FullJobInstance | null = null;

  public debounceTime = -1;

  public filter: SearchDashboardJobs | null = null;

  public uploadDate(date: string): string {
    const d = toParserDate(date);

    // eslint-disable-next-line
    return `${d.month.short} ${d.day.day}, ${d.year.count} ${d.time.hour[12]}:${d.time.minute} ${d.time.partDay.toUpperCase()}`;
  }

  public closeModals(): void {
    this.changeShowModalLoader(false);

    this.jobStatusData = null;
  }

  public download(id: number): void {
    this.changeShowModalLoader(true);

    requests.dashboard.getJobById(id).then((res) => {
      this.closeModals();
      this.jobStatusData = res.data;
    });
  }

  public filterColumn(column: SearchDashboardJobs): void {
    switch (this.filter) {
      case null:
        this.filter = column;
        break;
      case column:
        this.filter = `-${column}` as SearchDashboardJobs;
        break;
      case (`-${column}` as SearchDashboardJobs):
        this.filter = null;
        break;
      default:
        this.filter = column;
        break;
    }

    this.$emit('filter', this.filter);
  }

  public handleSearch(v: string | null): void {
    clearTimeout(this.debounceTime);

    this.debounceTime = setTimeout(() => {
      this.$emit('search', v);
    }, 400);
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }
}
