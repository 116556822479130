
import { Options, Vue } from 'vue-class-component';

import IconsService from '@/icons/Service.vue';
import IconQuestionCircle from '@/icons/other/QuestionCircle.vue';

import {
  JOB_ERROR_TYPE,
  JOB_ERROR_TYPE_TITLE,
  JOB_STATUS,
  JOB_STATUS_TITLE,
  ShortJobInfoInstance,
} from '@/interface/job.interface';

enum STATUS_ICON {
  check = 'check',
  refresh = 'refresh',
  inProgress = 'inProgress',
  exclamation = 'exclamation',
  remove = 'remove',
}

interface ListStatuses {
  [key: string]: {
    icon: STATUS_ICON,
    title: JOB_STATUS_TITLE,
  }
}

@Options({
  components: {
    IconsService,
    IconQuestionCircle,
  },
  props: {
    job: Object,
  },
})
export default class JobStatus extends Vue {
  declare $props: {
    job: ShortJobInfoInstance;
  }

  public JOB_ERROR_TYPE = JOB_ERROR_TYPE;

  public JOB_STATUS = JOB_STATUS;

  public showTooltip = false;

  public listStatus: ListStatuses = {
    ready: {
      icon: STATUS_ICON.check,
      title: JOB_STATUS_TITLE.ready,
    },
    completed: {
      icon: STATUS_ICON.check,
      title: JOB_STATUS_TITLE.completed,
    },
    in_progress: {
      icon: STATUS_ICON.inProgress,
      title: JOB_STATUS_TITLE.in_progress,
    },
    processing: {
      icon: STATUS_ICON.refresh,
      title: JOB_STATUS_TITLE.processing,
    },
    ignored: {
      icon: STATUS_ICON.remove,
      title: JOB_STATUS_TITLE.ignored,
    },
    mapping: {
      icon: STATUS_ICON.refresh,
      title: JOB_STATUS_TITLE.mapping,
    },
  }

  get title(): JOB_STATUS_TITLE | JOB_ERROR_TYPE_TITLE {
    if (this.$props.job.status === JOB_STATUS.error) {
      return JOB_ERROR_TYPE_TITLE[this.$props.job.errors[0].error_type];
    }

    return this.listStatus[this.$props.job.status].title;
  }

  get icon(): STATUS_ICON {
    if (this.$props.job.status === JOB_STATUS.error) {
      return STATUS_ICON.exclamation;
    }

    return this.listStatus[this.$props.job.status].icon;
  }

  public searchTypeError(job: ShortJobInfoInstance, error: JOB_ERROR_TYPE): boolean {
    return job.errors.some((e) => e.error_type === error);
  }
}
