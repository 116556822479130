
import { Options, Vue } from 'vue-class-component';

import IconTutorialArrow from '@/icons/other/TutorialArrow.vue';
import requests from '@/requests';

@Options({
  components: {
    IconTutorialArrow,
  },
  props: {
    el: Object,
    title: String,
    mirror: Boolean,
    swap: Boolean,
    loader: Boolean,
  },
  emits: {
    submit: Boolean,
  },
})
export default class Tutorial extends Vue {
  declare $props: {
    el: HTMLElement;
  }

  declare $refs: {
    tutorial: HTMLDivElement;
    content: HTMLDivElement;
  }

  private observer!: MutationObserver;

  public submit(): void {
    this.$emit('submit', true);
  }

  public turnOff(): void {
    const tutorials = {
      upload: true,
      options: true,
      mapping: true,
      purchase: true,
    };

    requests.profile.patchStatusTutorial(tutorials).then((res) => {
      this.$store.commit('set', { key: 'tutorial', payload: res.data });
    });
  }

  private setCoordinate(): void {
    const coordinate = { ...this.getCoords(this.$props.el) };

    const top = (coordinate.top + coordinate.height / 2).toFixed();
    const left = (coordinate.left + coordinate.width / 2).toFixed();

    this.$refs.tutorial.style.setProperty('top', `${top}px`);
    this.$refs.tutorial.style.setProperty('left', `${left}px`);

    this.autoSelectSide();
  }

  private getCoords(elem: HTMLElement) {
    const box = elem.getBoundingClientRect();

    const body = { ...document.body };
    const docEl = { ...document.documentElement };

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop || 0;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft || 0;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return {
      top,
      left,
      height: box.height,
      width: box.width,
    };
  }

  private autoSelectSide(): void {
    const html = document.documentElement.getBoundingClientRect();
    const el = this.$refs.content.getBoundingClientRect();

    if (el.right > html.width) {
      this.$refs.tutorial.classList.add('mirror');
    }

    if (el.bottom > html.bottom) {
      this.$refs.tutorial.classList.add('swap');
    }
  }

  private setObserver(): void {
    this.observer = new MutationObserver(this.setCoordinate);

    this.observer.observe(this.$props.el, { attributes: true, childList: true, subtree: true });
  }

  mounted(): void {
    this.setObserver();

    this.setCoordinate();

    document.documentElement.classList.add('tutorial-open');

    window.addEventListener('resize', this.setCoordinate);
  }

  unmounted(): void {
    window.removeEventListener('resize', this.setCoordinate);

    document.documentElement.classList.remove('tutorial-open');

    this.observer.disconnect();
  }
}
