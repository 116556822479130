import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "file_requirements_tooltip--container m-left-2"
}
const _hoisted_2 = { class: "file_requirements_tooltip" }
const _hoisted_3 = {
  key: 0,
  class: "file_requirements_tooltip--body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconQuestionCircle = _resolveComponent("IconQuestionCircle")!

  return (_ctx.tooltip)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_IconQuestionCircle, {
            class: "icon-question-circle",
            onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTooltip = true)),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTooltip = false))
          }),
          (_ctx.showTooltip)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tooltip)))
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}