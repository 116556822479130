import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "job-append-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoAppend = _resolveComponent("NoAppend")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.notNoAppend)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$props.type), { key: 0 }))
      : (_openBlock(), _createBlock(_component_NoAppend, { key: 1 }))
  ]))
}