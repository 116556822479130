
import { Options, Vue } from 'vue-class-component';

import IconDragNDrop from '@/icons/other/DragNDrop.vue';
import { VALID_FORMAT_TYPE_FILE } from '@/settings';

@Options({
  components: {
    IconDragNDrop,
  },
  props: {
    loader: Boolean,
  },
  emits: {
    file: Object,
  },
})
export default class ActiveJobEmptyTable extends Vue {
  declare $refs: {
    emptyTable: HTMLDivElement;
    form: HTMLFormElement;
  }

  public offset = 0;

  public fileTypes = Object.values(VALID_FORMAT_TYPE_FILE);

  public uploadFile(e: Event): void {
    const file = (e.target as HTMLInputElement).files?.item(0);

    this.$emit('file', () => file);

    this.$refs.form.reset();
  }

  private calcOffset(): void {
    const block = this.$refs.emptyTable;

    this.offset = Math.round(block.offsetHeight / 48);
  }

  mounted(): void {
    this.calcOffset();
  }
}
