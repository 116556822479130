import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tr", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.title, (t, tIndex) => {
        return (_openBlock(), _createElementBlock("th", { key: tIndex }, _toDisplayString(t), 1))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.$props.report), (d, dataIndex) => {
      return (_openBlock(), _createElementBlock("tr", { key: dataIndex }, [
        _createElementVNode("td", null, _toDisplayString(d), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.$props.report[d].Valid), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.$props.report[d].Invalid), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.$props.report[d].Unknown), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.$props.report[d].Total), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.$props.report[d].Percent), 1)
      ]))
    }), 128))
  ]))
}