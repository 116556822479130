
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue';
import { mapState } from 'vuex';

import Accordion from '@/common/Accordion.vue';

import Modal from '@/common/base/Modal.vue';

import IconHexagon from '@/icons/other/Hexagon.vue';
import IconFile from '@/icons/service/File.vue';
import IconWarning from '@/icons/service/Warning.vue';

import JobOptionsContainer from '@/common/JobOptionsContainer.vue';
import JobAppendContainer from '@/common/JobAppendContainer.vue';
import JobFileRequirementsContainer from '@/common/JobFileRequirementsContainer.vue';

import Tutorial from '@/common/Tutorial.vue';

import Loader from '@/common/Loader.vue';

import { JOB_TYPE, JOB_TYPE_TITLE } from '@/interface/job.interface';
import { downloadFileByLink, getPriceByType } from '@/utils';

import requests from '@/requests';
import methods from '@/methods';
import { JobPricing } from '@/interface/pricing.interface';
import { inactiveJobTypes, VALID_FORMAT_TYPE_FILE } from '@/settings';
import { LoaderStopPoint, LOADER_STATUS } from '@/interface/other.interface';
import { EditedOptions, JobOptions } from '@/common/JobOptions/jobOptions.interface';

@Options({
  components: {
    Accordion,
    Modal,
    IconHexagon,
    IconFile,
    IconWarning,
    JobOptionsContainer,
    JobAppendContainer,
    JobFileRequirementsContainer,
    Loader,
    Tutorial,
  },
  computed: {
    ...mapState(['profile', 'tutorial']),
  },
  props: {
    rawFile: File,
    pricing: Object,
    options: Object,
  },
  emits: {
    close: Boolean,
    finished: Boolean,
    requirements: Boolean,
    needPAFForm: Boolean,
  },
})

export default class ModalUploadNewJob extends Vue {
  declare $props: {
    rawFile: File;
    pricing: JobPricing;
    options: JobOptions;
  }

  declare $refs: {
    refDropdown: HTMLDivElement;
  }

  public isShowLoadFile = false;

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public editedOptions: { [key: string]: EditedOptions } = {};

  public file!: File;

  public fileName!: string;

  public fileType!: VALID_FORMAT_TYPE_FILE;

  get listJobType(): JOB_TYPE[] {
    return [
      JOB_TYPE.ncoa,
      JOB_TYPE.consumer_pcoa,
      JOB_TYPE.cima,
      JOB_TYPE.digital_audience,
      JOB_TYPE.landline_phone_append,
      JOB_TYPE.wireless_phone_append,
      JOB_TYPE.premium_business_phone_and_contact_append,
      JOB_TYPE.email_append_and_validate,
      JOB_TYPE.email_verification,
      JOB_TYPE.phone_disconnect,
      // JOB_TYPE.do_not_call_scrub,
      JOB_TYPE.reverse_email,
      JOB_TYPE.reverse_phone,
      JOB_TYPE.canadian_ncoa,
    ].filter((l) => inactiveJobTypes.some((i) => i !== l));
  }

  public selectedJobType = this.listJobType[0];

  public refDropdown = ref(null);

  public loaderTutorial = false;

  private countBlobs = -1;

  public selectJobType(type: JOB_TYPE): void {
    this.selectedJobType = type;
  }

  get selectedJobTypePrice(): string {
    return getPriceByType(this.selectedJobType, { ...this.$props.options, ...this.editedOptions }, this.$props.pricing);
  }

  public editOptions(type: keyof JobOptions, option: EditedOptions): void {
    if (JSON.stringify({ ...this.$props.options[type] }) === JSON.stringify({ ...option })) {
      delete this.editedOptions[type];
    } else {
      this.editedOptions[type] = { ...option };
    }
  }

  public closeModal(): void {
    this.$emit('close', true);
  }

  public renameFile(): void {
    const formData = new FormData();

    if (!this.fileName) {
      this.preparationFile();
    } else {
      formData.append('file', this.file, `${this.fileName}.${this.fileType}`);

      this.file = formData.get('file') as File;
    }
  }

  public downloadSampleFile(): void {
    this.changeShowModalLoader(true);

    requests.dashboard.downloadSampleFile(this.selectedJobType)
      .then((res) => { downloadFileByLink(res.data.file); })
      .catch(() => {
        const text = 'Sorry, we did not have time to add an example file for this job type.';
        alert(text);
      })
      .finally(() => { this.changeShowModalLoader(false); });
  }

  public startUploadFile(): void {
    const listNeedPAFForm: JOB_TYPE[] = [JOB_TYPE.ncoa, JOB_TYPE.consumer_pcoa];

    if (listNeedPAFForm.some((v) => v === this.selectedJobType) && !this.$store.state.profile?.has_paf_form) {
      this.$emit('needPAFForm', true);
      return;
    }

    if (Object.keys(this.editedOptions).length) {
      this.changeShowModalLoader(true);

      requests.dashboard.updateOptions(this.editedOptions)
        .then(() => { this.uploadFile(); })
        .finally(() => { this.changeShowModalLoader(false); });

      return;
    }

    this.uploadFile();
  }

  public handleChangeStatus(status: LOADER_STATUS): void {
    if (status === LOADER_STATUS.finished) {
      this.$emit('finished', true);
    }
  }

  public showJobFileRequirements(): void {
    this.$emit('requirements', true);
  }

  get generateStopsLoaderFile(): LoaderStopPoint[] {
    const points: LoaderStopPoint[] = [];

    const percent = Number((100 / this.countBlobs).toFixed(0));

    let count = 1;

    while (count <= this.countBlobs) {
      points.push({
        percent: percent * count,
        timeManipulation: this.getRandomInt(80, 300),
      });

      count += 1;
    }

    return points;
  }

  public submitUploadTutorial(): void {
    this.loaderTutorial = true;

    requests.profile.patchStatusTutorial({ options: true })
      .then((res) => {
        this.$store.commit('set', { key: 'tutorial', payload: res.data });
      })
      .finally(() => {
        this.loaderTutorial = false;
      });
  }

  private uploadFile(): void {
    methods.hash.getBlobParts(this.file).then((res) => {
      this.isShowLoadFile = true;
      this.countBlobs = res.parts.length + 1;
      requests.blob.uploadFile(res, this.selectedJobType);
    });
  }

  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private preparationFile(): void {
    this.file = this.$props.rawFile;

    const splitFileName = this.file.name.split('.');

    this.fileType = splitFileName.pop()! as VALID_FORMAT_TYPE_FILE;
    this.fileName = splitFileName.join('.').split(' ').join('_');

    this.renameFile();
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  created(): void {
    this.preparationFile();
  }
}
