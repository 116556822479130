
import { Options, Vue } from 'vue-class-component';
import { watch, WatchStopHandle } from 'vue';
import { JOB_TYPE } from '@/interface/job.interface';

/* eslint-disable camelcase */
import canadian_ncoa from './JobAppend/CanadianNCOA.vue';
import canadian_phone_append from './JobAppend/CanadianPhoneAppend.vue';
import cima from './JobAppend/CIMA.vue';
import consumer_pcoa from './JobAppend/ConsumerPCOA.vue';
import do_not_call_scrub from './JobAppend/DoNotCallScrub.vue';
import email_append_and_validate from './JobAppend/EmailAppendAndValidate.vue';
import email_verification from './JobAppend/EmailVerification.vue';
import landline_phone_append from './JobAppend/LandlinePhoneAppend.vue';
import ncoa from './JobAppend/NCOA.vue';
import NoAppend from './JobAppend/NoAppend.vue';
import phone_disconnect from './JobAppend/PhoneDisconnect.vue';
import premium_business_phone_and_contact_append from './JobAppend/PremiumBusinessPhoneAndContactAppend.vue';
import reverse_phone from './JobAppend/ReversePhone.vue';
import reverse_email from './JobAppend/ReverseEmail.vue';
import wireless_phone_append from './JobAppend/WirelessPhoneAppend.vue';
/* eslint-enable camelcase */

@Options({
  components: {
    canadian_ncoa,
    canadian_phone_append,
    cima,
    consumer_pcoa,
    do_not_call_scrub,
    email_append_and_validate,
    email_verification,
    landline_phone_append,
    ncoa,
    NoAppend,
    phone_disconnect,
    premium_business_phone_and_contact_append,
    reverse_phone,
    reverse_email,
    wireless_phone_append,
  },
  props: {
    type: String,
  },
})
export default class JobAppendContainer extends Vue {
  declare $props: {
    type: JOB_TYPE;
  }

  public notNoAppend = false;

  public watchStopHandle!: WatchStopHandle;

  private accessibleAppendTypes: string[] = []

  private checkType(): void {
    this.notNoAppend = this.accessibleAppendTypes.some((v) => v === this.$props.type);
  }

  created(): void {
    const components = { ...this.$options.components };
    const types: JOB_TYPE[] = (Object.keys(components) as (JOB_TYPE | 'NoAppend')[])
      .filter((v) => v !== 'NoAppend') as JOB_TYPE[];

    this.accessibleAppendTypes = types;

    this.checkType();

    this.watchStopHandle = watch(() => this.$props.type, () => this.checkType());
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
