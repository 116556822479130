
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue';
import { mapState } from 'vuex';

import Modal from '@/common/base/Modal.vue';
import Tutorial from '@/common/Tutorial.vue';

import Purchase from '@/modals/components/Purchase.vue';
import { JOB_STATUS, ShortJobInfoInstance } from '@/interface/job.interface';
import requests from '@/requests';
import { PaymentMethod } from '@/interface/payment.interface';
import { ModalsState, ToggleModal } from '@/store/modules/modals';

@Options({
  components: {
    Modal,
    Purchase,
    Tutorial,
  },
  computed: {
    ...mapState(['profile', 'tutorial']),
  },
  props: {
    job: Object,
  },
  emits: {
    close: Boolean,
    update: Boolean,
  },
})
export default class ModalStartJob extends Vue {
  declare $props: {
    job: ShortJobInfoInstance;
    refButton: HTMLButtonElement;
  }

  public refButton = ref(null);

  public paymentMethod: PaymentMethod | null = null;

  public loaderTutorial = false;

  public errors: string[] = [];

  public purchase(): void {
    if (this.paymentMethod) {
      this.changeShowModalLoader(true);

      if (!this.paymentMethod.default) {
        requests.billing.setDefaultPM(this.paymentMethod.stripe_id)
          .then(() => {
            this.updateJobStatus();
          })
          .catch(() => {
            this.changeShowModalLoader(false);
          });
      } else {
        this.updateJobStatus();
      }
    }
  }

  public submitUploadTutorial(): void {
    this.loaderTutorial = true;

    requests.profile.patchStatusTutorial({ purchase: true })
      .then((res) => {
        this.$store.commit('set', { key: 'tutorial', payload: res.data });
      })
      .finally(() => {
        this.loaderTutorial = false;
      });
  }

  public openModal(modal: keyof ModalsState): void {
    this.$store.commit('modals/toggle', { modal, status: true } as ToggleModal);
  }

  public closeModal(): void {
    this.$emit('close', true);
  }

  private updateJobStatus(): void {
    requests.dashboard.updateJobStatus(this.$props.job.id, JOB_STATUS.in_progress)
      .then(() => {
        this.$emit('update', true);
      })
      .catch((err) => {
        this.errors = err.response.data;
      })
      .finally(() => {
        this.changeShowModalLoader(false);
      });
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }
}
