
import { Options, Vue } from 'vue-class-component';

import Pagination from '@/common/Pagination/Pagination.vue';

import {
  FullJobInstance,
  JobMapFields,
  JOB_ERROR_TYPE,
  JOB_STATUS,
  JOB_TYPE_TITLE,
  ShortJobInfoInstance,
} from '@/interface/job.interface';

import SimpleModal from '@/common/SimpleModal.vue';

import IconMapping from '@/icons/service/Mapping.vue';

import ModalWeFound from '@/modals/WeFound.vue';
import ModalStartJob from '@/modals/StartJob.vue';
import ModalJobStatus from '@/modals/JobStatus.vue';

import JobFileName from '@/common/JobFileName.vue';

import IconsService from '@/icons/Service.vue';

import { JobListResponse } from '@/interface/requests.interface';
import { ReturnFile, SimpleModalButton } from '@/interface/other.interface';

import requests from '@/requests';
import JobStatus from './JobStatus.vue';
import DragNDropNewFile from './DragNDropNewFile.vue';
import EmptyTable from './EmptyTable.vue';

@Options({
  components: {
    JobStatus,
    JobFileName,
    IconsService,
    SimpleModal,
    EmptyTable,
    IconMapping,
    DragNDropNewFile,
    ModalWeFound,
    ModalStartJob,
    ModalJobStatus,
    Pagination,
  },
  props: {
    table: {
      type: Object,
      default: null,
    },
    allCountJobs: Number,
    limit: Number,
    loader: Boolean,
  },
  emits: {
    file: Object,
    update: Boolean,
    paginate: Number,
  },
})
export default class ActiveJob extends Vue {
  declare $props: {
    table: JobListResponse,
  }

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public JOB_STATUS = JOB_STATUS;

  public JOB_ERROR_TYPE = JOB_ERROR_TYPE;

  public jobMapping: JobMapFields | null = null;

  public job: ShortJobInfoInstance | null = null;

  public startJobData: ShortJobInfoInstance | null = null;

  public jobStatusData: FullJobInstance | null = null

  public isShowModalDeleteJob = false;

  public showDragNDrop = false;

  public modalDeleteJobButtons: SimpleModalButton[] = [
    { button: 'border', title: 'No', value: 'no' },
    { button: 'fill', title: 'Yes', value: -1 }, // value - it's ID job;
  ];

  public closeModals(): void {
    this.changeShowModalLoader(false);

    this.isShowModalDeleteJob = false;

    this.jobMapping = null;
    this.startJobData = null;
    this.jobStatusData = null;
  }

  public startJob(data: ShortJobInfoInstance): void {
    this.closeModals();
    this.startJobData = data;
  }

  public fixMapFields(job: ShortJobInfoInstance): void {
    this.changeShowModalLoader(true);

    requests.dashboard.getMapFields(job.id).then((res) => {
      this.closeModals();
      if (res.data.data) {
        this.jobMapping = res.data;
        this.job = job;
      }
    });
  }

  public download(id: number): void {
    this.changeShowModalLoader(true);

    requests.dashboard.getJobById(id).then((res) => {
      this.closeModals();
      this.jobStatusData = res.data;
    });
  }

  public showDeleteJob(id: number): void {
    const button = this.modalDeleteJobButtons[1];

    button.value = id;

    this.modalDeleteJobButtons.splice(1, 1, button);

    this.isShowModalDeleteJob = true;
  }

  public handleActionDeleteModal(value: number | 'no'): void {
    if (value !== 'no') {
      this.changeShowModalLoader(true);

      requests.dashboard.updateJobStatus(value, JOB_STATUS.ignored)
        .then(() => {
          this.updateActiveTable();
        })
        .finally(() => {
          this.changeShowModalLoader(false);
        });
    }

    this.closeModals();
  }

  public searchTypeError(job: ShortJobInfoInstance, error: JOB_ERROR_TYPE): boolean {
    return job.errors.some((e) => e.error_type === error);
  }

  public updateActiveTable(): void {
    this.closeModals();

    this.$emit('update', true);
  }

  public uploadFile(file: ReturnFile): void {
    this.$emit('file', () => file());
  }

  public paginate(page: number): void {
    this.$emit('paginate', page);
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }
}
