import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "loader__title" }
const _hoisted_2 = { class: "t-a-c" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader", _ctx.status])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        (_ctx.status === _ctx.LOADER_STATUS.pleaseWait)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.LOADER_STATUS_TITLE.pleaseWait), 1)
            ], 64))
          : (_ctx.status === _ctx.LOADER_STATUS.uploading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.LOADER_STATUS_TITLE.uploading) + " ", 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.percent) + "%", 1)
              ], 64))
            : (_ctx.status === _ctx.LOADER_STATUS.finished)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(_toDisplayString(_ctx.LOADER_STATUS_TITLE.finished), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createTextVNode(_toDisplayString(_ctx.LOADER_STATUS_TITLE.error), 1)
                ], 64))
      ])
    ]),
    _createElementVNode("div", {
      class: "loader__stripe",
      style: _normalizeStyle({ width:`${_ctx.percent}%`, opacity: _ctx.opacityLoaderStripe })
    }, null, 4)
  ], 2))
}