
import { Options, Vue } from 'vue-class-component';

import Accordion from '@/common/Accordion.vue';

@Options({
  components: {
    Accordion,
  },
})
export default class JobAppendReversePhone extends Vue {}
