
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue';
import { mapState } from 'vuex';

import { formatBytes, validateTypeFile } from '@/utils';
import requests from '@/requests';

import IconsService from '@/icons/Service.vue';

import ActiveJob from '@/views/dashboard/components/ActiveJob.vue';
import CompletedJob from '@/views/dashboard/components/CompletedJob.vue';

import SimpleModal from '@/common/SimpleModal.vue';
import ModalUploadNewJob from '@/modals/UploadNewJob.vue';
import ModalJobFileRequirements from '@/modals/JobFileRequirements.vue';

import Tutorial from '@/common/Tutorial.vue';

import { maxFileSize, VALID_FORMAT_TYPE_FILE, VALID_MIME_TYPE_FILE } from '@/settings';
import { JobPricing } from '@/interface/pricing.interface';
import { JobListRequest, JobListResponse } from '@/interface/requests.interface';
import { JOB_STATUS } from '@/interface/job.interface';
import { JobOptions } from '@/common/JobOptions/jobOptions.interface';
import {
  ReturnFile,
  ROUTER_NAMES,
  SearchDashboardJobs,
  SimpleModalButton,
} from '@/interface/other.interface';

@Options({
  components: {
    Tutorial,
    ActiveJob,
    CompletedJob,
    IconsService,
    SimpleModal,
    ModalUploadNewJob,
    ModalJobFileRequirements,
  },
  computed: {
    ...mapState(['profile', 'hijackingToken', 'tutorial']),
    ...mapState('modals', ['changeSubscription']),
  },
})
export default class ViewDashboard extends Vue {
  declare $refs: {
    form: HTMLFormElement;
    input: HTMLInputElement;
    refButton: HTMLButtonElement;
  }

  public AcceptMIMEType = Object.values(VALID_MIME_TYPE_FILE);

  public file: File | null = null;

  public jobOptions: JobOptions | null = null;

  public jobPricing: JobPricing | null = null;

  public modal = {
    needPAFForm: false,
    jobFileRequirements: false,
    maxFileSize: false,
    notSupported: false,
  }

  public limitJobsInTable = 10;

  public activeTableCurrentPage = 1;

  public completedTableLoader = false;

  public activeTableLoader = false;

  public refButton = ref(null);

  public fakeSocketStartInterval = -1;

  public loaderTutorial = false;

  public buttonsSimpleModal: SimpleModalButton[] = [
    { button: 'fill', title: 'OK', value: 'OK' },
  ];

  public tables: {
    active: JobListResponse | null,
    completed: JobListResponse | null
  } = {
    active: null,
    completed: null,
  }

  public countJobs = {
    active: 0,
    completed: 0,
  }

  public submitUploadTutorial(): void {
    this.loaderTutorial = true;

    requests.profile.patchStatusTutorial({ upload: true })
      .then((res) => {
        this.$store.commit('set', { key: 'tutorial', payload: res.data });
      })
      .finally(() => {
        this.loaderTutorial = false;
      });
  }

  public goToFormsPage(): void {
    this.$router.push({ name: ROUTER_NAMES.forms });
  }

  get activeTableParams(): JobListRequest {
    return {
      limit: this.limitJobsInTable,
      offset: this.limitJobsInTable * this.activeTableCurrentPage - this.limitJobsInTable,
      downloaded: false,
    };
  }

  get completedTableParams(): JobListRequest {
    const params: JobListRequest = {
      status: JOB_STATUS.completed,
      limit: this.limitJobsInTable,
      offset: 0,
      downloaded: true,
    };

    if (this.completedJobDynamicFilter.file) {
      params.file = this.completedJobDynamicFilter.file;
    }

    if (this.completedJobDynamicFilter.order_by) {
      params.order_by = this.completedJobDynamicFilter.order_by;
    }

    return params;
  }

  /* eslint-disable camelcase */
  private completedJobDynamicFilter: {
    file: string | null,
    order_by: SearchDashboardJobs | null,
  } = { file: '', order_by: null };
  /* eslint-enable camelcase */

  private timeout = -1;

  public uploadFile(e: Event): void {
    const rawFile = (e.target as HTMLInputElement).files?.item(0);

    if (rawFile) this.validateFile(rawFile);
  }

  public uploadValidFile(file: ReturnFile): void {
    this.validateFile(file());
  }

  public closeModal(): void {
    this.file = null;
    this.jobPricing = null;
    this.jobOptions = null;

    this.resetForm();
  }

  public finishedUploadFile(): void {
    this.closeModal();

    this.getListActiveJob();
  }

  public closeModalUploadNewJob(): void {
    if (!this.modal.jobFileRequirements) {
      this.file = null;
      this.jobPricing = null;
      this.jobOptions = null;
    }
  }

  public handleSearch(search: string | null): void {
    if (search) {
      this.completedJobDynamicFilter.file = search;
    } else {
      this.completedJobDynamicFilter.file = null;
    }

    this.getListCompletedJob(true);
  }

  public handleFilter(filter: SearchDashboardJobs | null): void {
    if (filter) {
      this.completedJobDynamicFilter.order_by = filter;
    } else {
      this.completedJobDynamicFilter.order_by = null;
    }

    this.getListCompletedJob(true);
  }

  public paginate(page: number): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.activeTableCurrentPage = page;

      this.getListActiveJob(true);
    }, 400);
  }

  get maxFileSizeTitle(): string {
    return `Please, split it into chunks and re-upload. <br/> Maximum file size is: ${formatBytes(maxFileSize)}`;
  }

  get supportedFileType(): string {
    const types = Object.values(VALID_FORMAT_TYPE_FILE);

    const result: string[] = [];

    types.forEach((v, i) => {
      if (i !== types.length - 1) {
        result.push(`.${v}`);
      } else {
        result.push(`and .${v}`);
      }
    });

    return `Please upload a CSV or Microsoft Excel file. <br/> Supported file extensions are ${result.join(' ')}`;
  }

  private validateFile(f: File): void {
    const file = validateTypeFile(f);

    if (file === null) {
      this.modal.notSupported = true;
      return;
    }

    if (file.size >= maxFileSize) {
      this.modal.maxFileSize = true;
      return;
    }

    this.file = file;
    this.getOptionsAndPricing();

    this.resetForm();
  }

  private resetForm(): void {
    this.$refs.form.reset();
  }

  private getListActiveJob(loader?: boolean): void {
    if (loader) {
      this.activeTableLoader = true;
    }

    requests.dashboard.getListJob(this.activeTableParams)
      .then((res) => {
        this.tables.active = null;
        this.tables.active = res.data;
        this.countJobs.active = res.data.count;

        this.fakeSocket(res.data);
      })
      .finally(() => {
        if (loader) {
          this.activeTableLoader = false;
        }
      });
  }

  private getListCompletedJob(loader?: boolean):void {
    if (loader) {
      this.completedTableLoader = true;
    }

    requests.dashboard.getListJob(this.completedTableParams)
      .then((res) => {
        this.tables.completed = res.data;
        this.countJobs.completed = res.data.count;
      })
      .finally(() => {
        if (loader) {
          this.completedTableLoader = false;
        }
      });
  }

  private getOptionsAndPricing(): void {
    this.changeShowModalLoader(true);

    const pricing = requests.billing.getJobPricing().then((res) => {
      this.jobPricing = res.data;
    });

    const options = requests.dashboard.getJobOptions().then((res) => {
      this.jobOptions = res.data;
    });

    Promise.all([pricing, options]).then(() => {
      this.changeShowModalLoader(false);
    });
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  private fakeSocket(activeJobs: JobListResponse): void {
    const status = [JOB_STATUS.processing, JOB_STATUS.in_progress, JOB_STATUS.mapping];

    const result = activeJobs.results.find((job) => (status.includes(job.status) ? job : false));

    clearInterval(this.fakeSocketStartInterval);

    if (result) {
      const timer = 10; // second;

      this.fakeSocketStartInterval = setInterval(() => {
        this.getListActiveJob(true);
      }, timer * 1000);
    }
  }

  created(): void {
    this.getListActiveJob();
    this.getListCompletedJob();
  }
}
