
// TODO: recheck with Max correct we show data or no;

import { Options, Vue } from 'vue-class-component';
import { FullJobInstance, JOB_TYPE, JOB_TYPE_TITLE } from '@/interface/job.interface';

import IconFinalPrice from '@/icons/other/FinalPrice.vue';
import IconHexagon from '@/icons/other/Hexagon.vue';
import IconFile from '@/icons/service/File.vue';
import IconCheck from '@/icons/service/Check.vue';

import Modal from '@/common/base/Modal.vue';

import JobOptionsContainer from '@/common/JobOptionsContainer.vue';

import Balance from '@/common/Balance.vue';

import { downloadFileByLink } from '@/utils';
import { BalanceResult } from '@/interface/other.interface';
import { EditedOptions, SuffixPrice } from '@/common/JobOptions/jobOptions.interface';

import requests from '@/requests';
import calcPricing from '@/common/JobOptions/calcPricing';
import JobStatusTables from './components/JobStatusTables.vue';

@Options({
  components: {
    JobStatusTables,
    IconFinalPrice,
    JobOptionsContainer,
    IconHexagon,
    IconCheck,
    IconFile,
    Balance,
    Modal,
  },
  props: {
    job: Object,
  },
  emits: {
    close: Boolean,
  },
})
export default class ModalJobStatus extends Vue {
  // TODO: проверить точно ли везде record в футеру;
  declare $props: {
    job: FullJobInstance;
  }

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public file = { name: '', type: '' };

  public balanceResult: BalanceResult | null = null;

  public download(): void {
    if (this.$props.job.result.archive) {
      downloadFileByLink(this.$props.job.result.archive);

      if (!this.$props.job.downloaded) {
        requests.dashboard.setJobDownload(this.$props.job.id)
          .then(() => { this.$emit('update', true); })
          .finally(() => { this.closeModal(); });
      } else {
        this.closeModal();
      }
    }
  }

  public getSuffix(type: JOB_TYPE): SuffixPrice {
    return calcPricing.detectedSuffixPrice(type) || 'record';
  }

  public closeModal(): void {
    this.$emit('close', true);
  }

  get getJobOptions(): { [key: string]: EditedOptions } {
    const obj: { [key: string]: EditedOptions } = {};

    obj[this.$props.job.job_type] = this.$props.job.options;

    return obj;
  }

  private parserFile(): void {
    const name = this.$props.job.file.split('.');

    this.file.type = name.pop()!;
    this.file.name = name.join('.');
  }

  created(): void {
    this.parserFile();
  }
}
