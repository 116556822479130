<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.8418 62.2204C35.0383 57.1378 45.3618 59.4489 50.7574 57.0009C56.1529 54.5511 66.0765 46.3058 54.8107 21.4844C43.5449 -3.33689 26.6489 -0.0622192 20.7129 2.63111C-7.07728 15.2444 4.01073 71.2213 23.8418 62.2204Z" fill="#EFEFEF"/>
    <path d="M19.1074 7.37885L21.6216 4.86469L22.8787 6.12177L20.3645 8.63592L19.1074 7.37885Z" fill="#646995"/>
    <path d="M11.8789 14.6061L14.3931 12.0919L15.6501 13.349L13.136 15.8632L11.8789 14.6061Z" fill="#646995"/>
    <path d="M11.8789 5.80644L13.136 4.54936L15.6501 7.06352L14.3931 8.3206L11.8789 5.80644Z" fill="#646995"/>
    <path d="M59.0632 48.8871C57.5929 48.8871 56.3965 47.6907 56.3965 46.2204C56.3965 44.7502 57.5929 43.5538 59.0632 43.5538C60.5334 43.5538 61.7298 44.7502 61.7298 46.2204C61.7298 47.6907 60.5334 48.8871 59.0632 48.8871ZM59.0632 45.3316C58.5725 45.3316 58.1743 45.7316 58.1743 46.2204C58.1743 46.7093 58.5725 47.1093 59.0632 47.1093C59.5538 47.1093 59.952 46.7093 59.952 46.2204C59.952 45.7316 59.5538 45.3316 59.0632 45.3316Z" fill="#646995"/>
    <path d="M18.4 32.8889L15.0578 36.3022C13.1022 38.2933 12 40.9778 12 43.7689V52H25.3333V49.9911C26.9689 49.0133 27.9644 47.2356 28 45.3333L18.4 32.8889Z" fill="#F3F7FC"/>
    <path d="M45.6 32.8889L48.9422 36.3022C50.8978 38.2933 52 40.9778 52 43.7689V52H38.6667V49.9911C37.0311 49.0133 36.0356 47.2356 36 45.3333L45.6 32.8889Z" fill="#F3F7FC"/>
    <path d="M42.9596 44.8889H21.0627C19.9978 44.8889 19.1338 44.0249 19.1338 42.96V13.9289C19.1338 12.864 19.9978 12 21.0627 12H42.9596C44.0244 12 44.8884 12.864 44.8884 13.9289V42.96C44.8884 44.0249 44.0244 44.8889 42.9596 44.8889Z" fill="#3AC66E"/>
    <path d="M47.4723 41.3938L41.3106 35.4524C40.7399 34.9031 39.7799 35.0524 39.419 35.7493C38.971 36.6133 39.067 37.648 39.6697 38.4196L43.0812 42.8053C41.4635 44.3644 41.4635 46.8924 43.0812 48.4515L47.4723 41.3938Z" fill="#F3F7FC"/>
    <path d="M22.6893 42.96V13.9289C22.6893 12.864 23.5533 12 24.6182 12H21.0627C19.9978 12 19.1338 12.864 19.1338 13.9289V42.96C19.1338 44.0249 19.9978 44.8889 21.0627 44.8889H24.6182C23.5533 44.8889 22.6893 44.0249 22.6893 42.96Z" fill="#3AC66E"/>
    <path d="M16.5283 41.3938L22.6901 35.4524C23.2608 34.9031 24.2208 35.0524 24.5816 35.7493C25.0296 36.6133 24.9336 37.648 24.331 38.4196L20.9194 42.8053C22.5372 44.3644 22.5372 46.8924 20.9194 48.4515L16.5283 41.3938Z" fill="#F3F7FC"/>
    <path d="M18.6133 36.3022L19.9662 34.9191L18.4 32.8889L15.0578 36.3022C13.1022 38.2933 12 40.9778 12 43.7689V52H15.5556V43.7689C15.5556 40.9778 16.6578 38.2933 18.6133 36.3022Z" fill="#C4D6F0"/>
    <path d="M48.9416 36.3022L45.5994 32.8889L44.0332 34.9191L45.3861 36.3022C47.3416 38.2933 48.4439 40.9778 48.4439 43.7689V52H51.9994V43.7689C51.9994 40.9778 50.8972 38.2933 48.9416 36.3022Z" fill="#C4D6F0"/>
    <path d="M22.2227 43.5556H41.6307V46.2222H22.2227V43.5556Z" fill="#383B53"/>
    <path d="M43.5557 21.2284H46.2223V38.7911H43.5557V21.2284Z" fill="#383B53"/>
    <path d="M20.444 38.7911H17.7773V13.7778C17.7773 12.0622 19.1729 10.6667 20.8885 10.6667H43.1107C44.8262 10.6667 46.2218 12.0622 46.2218 13.7778V17.472H43.5551V13.7778C43.5551 13.5325 43.356 13.3334 43.1107 13.3334H20.8885C20.6431 13.3334 20.444 13.5325 20.444 13.7778V38.7911Z" fill="#383B53"/>
    <path d="M21.8453 49.4115L19.9946 47.4915C20.5137 46.992 20.8 46.3307 20.8 45.6284C20.8 44.9262 20.5137 44.2649 19.9946 43.7653C19.504 43.2907 19.4488 42.5244 19.8684 41.9858L23.28 37.6C23.5182 37.2924 23.5875 36.9049 23.4755 36.5476L17.4542 42.3538L15.6035 40.4338L21.7653 34.4924C22.3377 33.9431 23.1466 33.6925 23.9395 33.8187C24.7342 33.9484 25.3991 34.4284 25.7653 35.1324C26.4497 36.4516 26.2986 38.0622 25.3848 39.2391L22.6257 42.7858C23.1751 43.6284 23.4684 44.6044 23.4684 45.6284C23.4666 47.0613 22.8906 48.4053 21.8453 49.4115Z" fill="#383B53"/>
    <path d="M25.3337 53.3333H12.0003C11.2643 53.3333 10.667 52.736 10.667 52V43.7689C10.667 40.6115 11.8883 37.6267 14.107 35.3671L17.4474 31.9556L19.3532 33.8204L16.011 37.2338C14.2848 38.9938 13.3337 41.3138 13.3337 43.7689V50.6667H24.0003V49.9911C24.0003 49.5218 24.2474 49.0862 24.6492 48.8462C25.867 48.1191 26.6403 46.7627 26.667 45.3084L29.3337 45.3564C29.2945 47.4578 28.2901 49.4364 26.667 50.6915V51.9982C26.667 52.736 26.0697 53.3333 25.3337 53.3333Z" fill="#383B53"/>
    <path d="M42.1543 49.4115C41.109 48.4053 40.533 47.0613 40.533 45.6284C40.533 44.6062 40.8263 43.6284 41.3757 42.7858L38.6166 39.2391C37.701 38.064 37.5517 36.4533 38.2343 35.1342C38.6006 34.4284 39.2672 33.9484 40.0601 33.8187C40.8548 33.6907 41.6637 33.9413 42.2361 34.4924L48.3979 40.4338L46.5472 42.3538L40.5259 36.5476C40.4139 36.9031 40.4832 37.2924 40.7214 37.6L44.133 41.9858C44.5525 42.5244 44.4974 43.2907 44.0068 43.7653C43.4877 44.2649 43.2014 44.9262 43.2014 45.6284C43.2014 46.3307 43.4877 46.992 44.0068 47.4915L42.1543 49.4115Z" fill="#383B53"/>
    <path d="M52.0003 53.3333H38.667C37.931 53.3333 37.3337 52.736 37.3337 52V50.6933C35.7105 49.4382 34.7061 47.4595 34.667 45.3582L37.3337 45.3102C37.3603 46.7644 38.1337 48.1209 39.3514 48.848C39.755 49.088 40.0003 49.5235 40.0003 49.9929V50.6684H50.667V43.7707C50.667 41.3156 49.7159 38.9938 47.9914 37.2373L44.6474 33.8222L46.5532 31.9573L49.8954 35.3707C52.1123 37.6267 53.3337 40.6116 53.3337 43.7689V52C53.3337 52.736 52.7363 53.3333 52.0003 53.3333Z" fill="#383B53"/>
  </svg>
  <!-- eslint-enable -->
</template>
