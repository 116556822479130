
import { Options, Vue } from 'vue-class-component';

/* eslint-disable camelcase */
import { JOB_TYPE } from '@/interface/job.interface';
import phone_disconnect from './JobStatusTables/PhoneDisconnect.vue';
import email_verification from './JobStatusTables/EmailVerification.vue';
import consumer_pcoa from './JobStatusTables/PCOA.vue';
import cima from './JobStatusTables/CIMA.vue';
import landline_phone_append from './JobStatusTables/LandlinePhoneAppend.vue';
import premium_business_phone_and_contact_append from './JobStatusTables/PremiumBusinessPhoneAndContactAppend.vue';
import wireless_phone_append from './JobStatusTables/WirelessPhoneAppend.vue';

/* eslint-enable camelcase */

@Options({
  components: {
    phone_disconnect,
    email_verification,
    consumer_pcoa,
    cima,
    landline_phone_append,
    premium_business_phone_and_contact_append,
    wireless_phone_append,
  },
  props: {
    type: String,
    report: [Object, Array],
  },
})
export default class JobStatusTable extends Vue {
  declare $props: {
    type: JOB_TYPE;
    report: any;
  }

  public table: string | null = null;

  created(): void {
    const table: JOB_TYPE[] = [
      JOB_TYPE.phone_disconnect,
      JOB_TYPE.email_verification,
      JOB_TYPE.consumer_pcoa,
      JOB_TYPE.cima,
      JOB_TYPE.landline_phone_append,
      JOB_TYPE.premium_business_phone_and_contact_append,
      JOB_TYPE.wireless_phone_append,
    ];

    this.table = table.find((i: string) => i === this.$props.type) || null;
  }
}
